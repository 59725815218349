#cms-page  {
    font-size: clamp(1.125rem, 0.971rem + 0.493vw, 1.5625rem);
    color: #3A3A3A;
}

#cms-page h2 {
    font-size: clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem);
    margin: clamp(1.5625rem, 0.3521rem + 3.8732vw, 5rem) 0;
}

#cms-page a {
    text-decoration: underline;
}

/* styles from wp */
.wp-block-columns {
    align-items: normal !important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap !important;
    gap: 40px
}

@media (min-width: 782px) {
    .wp-block-columns {
        flex-wrap: nowrap !important
    }
}

.wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start
}

.wp-block-columns.are-vertically-aligned-center {
    align-items: center
}

.wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end
}

@media (max-width: 781px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
        flex-basis: 100% !important
    }
}

@media (min-width: 782px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
        flex-basis: 0;
        flex-grow: 1
    }

    .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column[style*=flex-basis] {
        flex-grow: 0
    }
}

.wp-block-columns.is-not-stacked-on-mobile {
    flex-wrap: nowrap !important
}

.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1
}

.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column[style*=flex-basis] {
    flex-grow: 0
}

:where(.wp-block-columns) {
    margin-bottom: 1.75em
}

:where(.wp-block-columns.has-background) {
    padding: 1.25em 2.375em
}

.wp-block-column {
    flex-grow: 1;
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-word
}

.wp-block-column.is-vertically-aligned-top {
    align-self: flex-start
}

.wp-block-column.is-vertically-aligned-center {
    align-self: center
}

.wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end
}

.wp-block-column.is-vertically-aligned-stretch {
    align-self: stretch
}

.wp-block-column.is-vertically-aligned-bottom, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-top {
    width: 100%
}


:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color--rgb: 0, 124, 186;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
    --wp-admin-theme-color-darker-20: #005a87;
    --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
    --wp-admin-border-width-focus: 2px;
    --wp-block-synced-color: #7a00df;
    --wp-block-synced-color--rgb: 122, 0, 223
}

@media (min-resolution: 192dpi) {
    :root {
        --wp-admin-border-width-focus: 1.5px
    }
}

.wp-element-button {
    cursor: pointer
}

:root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px
}

:root .has-very-light-gray-background-color {
    background-color: #eee
}

:root .has-very-dark-gray-background-color {
    background-color: #313131
}

:root .has-very-light-gray-color {
    color: #eee
}

:root .has-very-dark-gray-color {
    color: #313131
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
    background: linear-gradient(135deg, #00d084, #0693e3)
}

:root .has-purple-crush-gradient-background {
    background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe)
}

:root .has-hazy-dawn-gradient-background {
    background: linear-gradient(135deg, #faaca8, #dad0ec)
}

:root .has-subdued-olive-gradient-background {
    background: linear-gradient(135deg, #fafae1, #67a671)
}

:root .has-atomic-cream-gradient-background {
    background: linear-gradient(135deg, #fdd79a, #004a59)
}

:root .has-nightshade-gradient-background {
    background: linear-gradient(135deg, #330968, #31cdcf)
}

:root .has-midnight-gradient-background {
    background: linear-gradient(135deg, #020381, #2874fc)
}

.has-regular-font-size {
    font-size: 1em
}

.has-larger-font-size {
    font-size: 2.625em
}

.has-normal-font-size {
    font-size: var(--wp--preset--font-size--normal)
}

.has-huge-font-size {
    font-size: var(--wp--preset--font-size--huge)
}

.has-text-align-center {
    text-align: center
}

.has-text-align-left {
    text-align: left
}

.has-text-align-right {
    text-align: right
}

#end-resizable-editor-section {
    display: none
}

.aligncenter {
    clear: both
}

.items-justified-left {
    justify-content: flex-start
}

.items-justified-center {
    justify-content: center
}

.items-justified-right {
    justify-content: flex-end
}

.items-justified-space-between {
    justify-content: space-between
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    word-wrap: normal !important;
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.screen-reader-text:focus {
    clip: auto !important;
    background-color: #ddd;
    -webkit-clip-path: none;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
}

html :where(.has-border-color) {
    border-style: solid
}

html :where([style*=border-top-color]) {
    border-top-style: solid
}

html :where([style*=border-right-color]) {
    border-right-style: solid
}

html :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

html :where([style*=border-left-color]) {
    border-left-style: solid
}

html :where([style*=border-width]) {
    border-style: solid
}

html :where([style*=border-top-width]) {
    border-top-style: solid
}

html :where([style*=border-right-width]) {
    border-right-style: solid
}

html :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

html :where([style*=border-left-width]) {
    border-left-style: solid
}

html :where(img[class*=wp-image-]) {
    height: auto;
    max-width: 100%
}

:where(figure) {
    margin: 0 0 1em
}

html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

@media screen and (max-width: 600px) {
    html :where(.is-position-sticky) {
        --wp-admin--admin-bar--position-offset: 0px
    }
}


.wp-block-image img {
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: bottom
}

.wp-block-image[style*=border-radius] img, .wp-block-image[style*=border-radius] > a {
    border-radius: inherit
}

.wp-block-image.has-custom-border img {
    box-sizing: border-box
}

.wp-block-image.aligncenter {
    text-align: center
}

.wp-block-image.alignfull img, .wp-block-image.alignwide img {
    height: auto;
    width: 100%
}

.wp-block-image .aligncenter, .wp-block-image .alignleft, .wp-block-image .alignright, .wp-block-image.aligncenter, .wp-block-image.alignleft, .wp-block-image.alignright {
    display: table
}

.wp-block-image .aligncenter > figcaption, .wp-block-image .alignleft > figcaption, .wp-block-image .alignright > figcaption, .wp-block-image.aligncenter > figcaption, .wp-block-image.alignleft > figcaption, .wp-block-image.alignright > figcaption {
    caption-side: bottom;
    display: table-caption
}

.wp-block-image .alignleft {
    float: left;
    margin: .5em 1em .5em 0
}

.wp-block-image .alignright {
    float: right;
    margin: .5em 0 .5em 1em
}

.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto
}

.wp-block-image figcaption {
    margin-bottom: 1em;
    margin-top: .5em
}

.wp-block-image .is-style-rounded img, .wp-block-image.is-style-circle-mask img, .wp-block-image.is-style-rounded img {
    border-radius: 9999px
}

@supports ((-webkit-mask-image: none) or (mask-image:none)) or (-webkit-mask-image:none) {
    .wp-block-image.is-style-circle-mask img {
        border-radius: 0;
        -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-mode: alpha;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain
    }
}

.wp-block-image :where(.has-border-color) {
    border-style: solid
}

.wp-block-image :where([style*=border-top-color]) {
    border-top-style: solid
}

.wp-block-image :where([style*=border-right-color]) {
    border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-color]) {
    border-left-style: solid
}

.wp-block-image :where([style*=border-width]) {
    border-style: solid
}

.wp-block-image :where([style*=border-top-width]) {
    border-top-style: solid
}

.wp-block-image :where([style*=border-right-width]) {
    border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-width]) {
    border-left-style: solid
}

.wp-block-image figure {
    margin: 0
}

.wp-lightbox-container {
    display: flex;
    flex-direction: column;
    position: relative
}

.wp-lightbox-container img {
    cursor: zoom-in
}

.wp-lightbox-container img:hover + button {
    opacity: 1
}

.wp-lightbox-container button {
    align-items: center;
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(90, 90, 90, .25);
    border: none;
    border-radius: 4px;
    cursor: zoom-in;
    display: flex;
    height: 20px;
    justify-content: center;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 16px;
    text-align: center;
    top: 16px;
    transition: opacity .2s ease;
    width: 20px;
    z-index: 100
}

.wp-lightbox-container button:focus-visible {
    outline: 3px auto rgba(90, 90, 90, .25);
    outline: 3px auto -webkit-focus-ring-color;
    outline-offset: 3px
}

.wp-lightbox-container button:hover {
    cursor: pointer;
    opacity: 1
}

.wp-lightbox-container button:focus {
    opacity: 1
}

.wp-lightbox-container button:focus, .wp-lightbox-container button:hover, .wp-lightbox-container button:not(:hover):not(:active):not(.has-background) {
    background-color: rgba(90, 90, 90, .25);
    border: none
}

.wp-lightbox-overlay {
    box-sizing: border-box;
    cursor: zoom-out;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: 100000
}

.wp-lightbox-overlay .close-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 40px;
    padding: 0;
    position: absolute;
    right: calc(env(safe-area-inset-right) + 16px);
    top: calc(env(safe-area-inset-top) + 16px);
    z-index: 5000000
}

.wp-lightbox-overlay .close-button:focus, .wp-lightbox-overlay .close-button:hover, .wp-lightbox-overlay .close-button:not(:hover):not(:active):not(.has-background) {
    background: none;
    border: none
}

.wp-lightbox-overlay .lightbox-image-container {
    height: var(--wp--lightbox-container-height);
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: var(--wp--lightbox-container-width);
    z-index: 9999999999
}

.wp-lightbox-overlay .wp-block-image {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    position: relative;
    transform-origin: 0 0;
    width: 100%;
    z-index: 3000000
}

.wp-lightbox-overlay .wp-block-image img {
    height: var(--wp--lightbox-image-height);
    min-height: var(--wp--lightbox-image-height);
    min-width: var(--wp--lightbox-image-width);
    width: var(--wp--lightbox-image-width)
}

.wp-lightbox-overlay .wp-block-image figcaption {
    display: none
}

.wp-lightbox-overlay button {
    background: none;
    border: none
}

.wp-lightbox-overlay .scrim {
    background-color: #fff;
    height: 100%;
    opacity: .9;
    position: absolute;
    width: 100%;
    z-index: 2000000
}

.wp-lightbox-overlay.active {
    animation: turn-on-visibility .25s both;
    visibility: visible
}

.wp-lightbox-overlay.active img {
    animation: turn-on-visibility .35s both
}

.wp-lightbox-overlay.hideanimationenabled:not(.active) {
    animation: turn-off-visibility .35s both
}

.wp-lightbox-overlay.hideanimationenabled:not(.active) img {
    animation: turn-off-visibility .25s both
}

@media (prefers-reduced-motion: no-preference) {
    .wp-lightbox-overlay.zoom.active {
        animation: none;
        opacity: 1;
        visibility: visible
    }

    .wp-lightbox-overlay.zoom.active .lightbox-image-container {
        animation: lightbox-zoom-in .4s
    }

    .wp-lightbox-overlay.zoom.active .lightbox-image-container img {
        animation: none
    }

    .wp-lightbox-overlay.zoom.active .scrim {
        animation: turn-on-visibility .4s forwards
    }

    .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) {
        animation: none
    }

    .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .lightbox-image-container {
        animation: lightbox-zoom-out .4s
    }

    .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .lightbox-image-container img {
        animation: none
    }

    .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .scrim {
        animation: turn-off-visibility .4s forwards
    }
}

@keyframes turn-on-visibility {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes turn-off-visibility {
    0% {
        opacity: 1;
        visibility: visible
    }

    99% {
        opacity: 0;
        visibility: visible
    }

    to {
        opacity: 0;
        visibility: hidden
    }
}

@keyframes lightbox-zoom-in {
    0% {
        transform: translate(calc(-50vw + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale))
    }

    to {
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes lightbox-zoom-out {
    0% {
        transform: translate(-50%, -50%) scale(1);
        visibility: visible
    }

    99% {
        visibility: visible
    }

    to {
        transform: translate(calc(-50vw + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale));
        visibility: hidden
    }
}

